import React from "react"
// import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import kebabCase from "lodash/kebabCase"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"

export default ({ data }) => {
  return (
    <Layout>
      <Helmet title={`vokabox.com - Blog`} />
      <div className={"page-header"}>
        <div className={"container"}>
          <h1>
            vokabox{" "}
            <span className={"Highlight--highlight_wrapper"}>
              <span className={"underline-sprkr"}>Blog</span>
              <span className={"Highlight--highlight_color"}></span>
            </span>
          </h1>
        </div>
      </div>
      <div className={"container blog-container"}>
        <section>
          {data.posts.edges.map(({ node }) => {
            const { slug } = node.fields
            const { title, author, date, featuredImage } = node.frontmatter
            const { excerpt } = node

            const img = featuredImage ? (
              <Img fluid={featuredImage.childImageSharp.fluid} />
            ) : (
              ""
            )

            return (
              <article className={"blog-article"} key={slug}>
                {img}
                <h2 className={"entry-title"}>
                  <Link to={slug}>{title}</Link>
                  {/* ({node.timeToRead} min) */}
                </h2>
                <div className={"entry-info"}>
                  <address>
                    by {/* <Link to={slug}>{author name}</Link> */}
                    {author} {" / "}
                    {date}
                  </address>
                </div>
                <div className={"entry-content"}>
                  <p>
                    <em>{excerpt}</em>
                    <br />
                    <em className={"read-more"}>
                      <Link to={slug}>Read More...</Link>
                    </em>
                  </p>
                </div>
              </article>
            )
          })}
        </section>
        <section className={"topicLinks"}>
          <h1>Topics</h1>
          <ul>
            {data.tagsGroup.group.map(tag => (
              <li key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "posts" } } }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            author
            featuredImage {
              childImageSharp {
                resize(width: 1500, height: 1500) {
                  src
                }
                fluid(maxWidth: 786) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
          fields {
            slug
          }
          timeToRead
          excerpt
        }
      }
      totalCount
    }
    tagsGroup: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
